/* eslint-disable no-console */
import toast from 'react-hot-toast';

import routes from '@/services/routes';
import { type UserContent } from '../with_auth/State';

// This hash provides the _next_ state for a given onboarding wizard page!
export const STATES = {
    howSixtyWorks: 0,
    connectedAccounts: 1,
    authorizeAccount: 2,
    analyzingData: 3,
    inviteFriends: 4,
    mySixtyDashboard: 5,
};
export const ONBOARDING_NAMES = {
    0: 'Welcome to Sixty!',
    1: 'Connected Accounts',
    2: 'Authorize Account',
    3: 'Analyzing Data',
    4: 'Invite Friends',
};

// This array holds the routes matching their onboarding state order
// So index 1 = the route to authorize account
const ROUTE_ORDER = [
    routes.ONBOARDING_HOW_SIXTY_WORKS,
    routes.ONBOARDING_AUTHORIZE_ACCOUNT,
    routes.ONBOARDING_CONNECTED_ACCOUNTS,
    routes.ONBOARDING_ANALYZING_DATA,
    routes.ONBOARDING_INVITE,
    routes.GETTING_STARTED,
];

export const BIO_STATES = {
    overview: 0,
    authorize: 1,
    personalize: 2,
    career: 3,
    selectBio: 4,
    reviseBio: 5,
    editBio: 6,
    communicationPreferences: 7,
    meetingPreferences: 8,
    socialLinks: 9,
    review: 10,
};

export const BIO_NAMES = {
    0: 'About you',
    1: 'Career history',
    2: 'Your bio',
    3: 'Preferences',
    4: 'Social',
    5: 'Review & share',
};

const BIO_ROUTE_ORDER = [
    routes.BIO_OVERVIEW,
    routes.BIO_AUTHORIZE,
    routes.BIO_PERSONALIZE,
    routes.BIO_CAREER_HISTORY,
    routes.BIO_SELECT_STARTER_BIO,
    routes.BIO_REVISE_BIO,
    routes.BIO_EDIT_BIO,
    routes.BIO_MEETING_PREFERENCES,
    routes.BIO_COMMUNICATION_PREFERENCES,
    routes.BIO_SOCIAL_LINKS,
    routes.BIO_REVIEW_BIO,
];

export const FINAL_STATE = STATES[Object.keys(STATES).pop()];

export const FINAL_BIO_STATE = BIO_STATES[Object.keys(BIO_STATES).pop()];

export function getStateName(state: number): string | undefined {
    const keys = Object.keys(state);
    for (let i = 0; i < keys.length; i++) {
        if (STATES[keys[i]] === state) {
            return keys[i];
        }
    }

    return undefined;
}

export function birthdayFormat(month, day, year): Array<string> {
    if ((month && !day) || (day && !month)) {
        return [null, 'Month and Day are required if either is provided.'];
    }

    if (!month && !day && !year) {
        return [null, null];
    }

    if (!year) {
        // eslint-disable-next-line no-param-reassign
        year = '1600';
    }

    const datestring = `${year}-${month}-${day}`;
    return [new Date(datestring).toISOString(), null];
}

export function isOnboardingNow(router) {
    return router && router.asPath.startsWith(routes.ONBOARDING_PREFIX);
}

export function isOnboardingCompleted(user) {
    const state = user.onboardingState;
    return (state && state >= FINAL_STATE);
}

/**
 * Get the onboardingState from the user and limit the max valid state
 * based on our state objects. Default to 0 if the user has no onboarding state.
 * The offset returns a state with that amount of offset from the current users
 * onboarding state. offset = 0, no change, offset = 1, next state, offset = -1, prev state
 */
export const getNewOnboardingState = (user: UserContent, offset: number = 0) => {
    const max = Object.keys(STATES).length;
    let { onboardingState } = user;

    // If we don't have a user onboarding state, make it 0
    if (!onboardingState) {
        onboardingState = 0;
    }
    onboardingState += offset;
    // Limit the response to 0 and length - 1 of whatever onboarding path we're on
    return Math.min(Math.max(onboardingState, 0), max);
};

/**
 * Same as getOnboardingState, but also converts the state into a route
 */
export const getOnboardingUrl = (user: UserContent, offset: number = 0) => {
    const onboardingState = getNewOnboardingState(user, offset);
    const url = user.bioOnly ? BIO_ROUTE_ORDER[onboardingState] : ROUTE_ORDER[onboardingState];

    if (!url) {
        return routes.ROOT;
    }

    return url;
};

export const nextOnboardingURL = (user) => getOnboardingUrl(user, 1);

export const previousOnboardingURL = (user) => getOnboardingUrl(user, -1);

export const getPreviousState = (user: UserContent): number => getNewOnboardingState(user, -1);

export const haveErrors = (errors) => errors && Object.keys(errors).length !== 0;

export const reportGeneralError = (errors) => {
    // TODO: Report to Sentry, and try to present the info to the user.
    // Since the errors here shouldn't be form-field-related, we want to show
    // a generalized error block.
    console.log('General error:', errors);
    toast.error('Something went wrong, please try again.');
};

export default {
    haveErrors,
    reportGeneralError,
    isOnboardingCompleted,
};
