import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { useRecoilValue } from 'recoil';

import { onboardingComplete, onboardingRemindLater } from '@/components/with_auth/State';
import routes from '@/services/routes';
import Link from '@/components/commons/link';
import IconWrapper from '@/components/commons/IconWrapper';
import StarOutline from '@/components/commons/icons/StartOutline';
import Chart from '@/components/commons/icons/Chart';
import EnvelopeOutline from '@/components/commons/icons/EnvelopeOutline';
import AtomOutline from '@/components/commons/icons/AtomOutline';
import Button, { ButtonType } from '@/components/commons/button';
import ChevronDown from '@/components/commons/icons/ChevronDown';

import styles from './AppSidebar.module.css';

const hasTrailingSlash = (path) => path.endsWith('/');

function Sidebar({ onClick = (_event) => { } }) {
    const router = useRouter();
    const [showInboxList, setShowInboxList] = useState(true);
    const [showCalendarList, setShowCalendarList] = useState(true);
    const effectivePath = hasTrailingSlash(router.asPath) ? router.asPath : `${router.asPath}/`;
    const isOnboardingComplete = useRecoilValue(onboardingComplete);
    const remindLater = useRecoilValue(onboardingRemindLater);

    const doNothing = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    // we hide the getting started if user completed all onboarding or it hasn't been a week
    // since they asked to be reminded
    const hideGettingStarted = isOnboardingComplete || remindLater;

    return (
        <div className="flex flex-col">
            {!hideGettingStarted ? (
                <Link
                    to={routes.GETTING_STARTED}
                    className={`${styles.link} ${styles.heading} ${styles.inactive}`}
                    active={router.asPath.startsWith(routes.GETTING_STARTED)}
                    activeClassName={`${styles.link} ${styles.heading} ${styles.active}`}
                    onClick={onClick}
                >
                    <StarOutline className="w-4 h-4 mr-3" />
                    Getting Started
                </Link>
            ) : null}

            <Link
                to={routes.ROOT}
                className={`${styles.link} ${styles.heading} ${styles.inactive}`}
                active={effectivePath === routes.ROOT}
                activeClassName={`${styles.link} ${styles.heading} ${styles.active}`}
                onClick={onClick}
            >
                <Chart className="w-4 h-4 mr-3" />
                Insights
            </Link>
            <Link
                to={routes.INBOX}
                className={`fa-solid fa-robot flex justify-between ${styles.link} ${styles.heading} ${styles.inactive}`}
                active={router.asPath.startsWith(routes.INBOX)}
                activeClassName={`${styles.link} ${styles.heading} ${styles.active}`}
                onClick={onClick}
            >
                <div className="flex">
                    <EnvelopeOutline className="w-4 h-4 mr-3" />
                    Inbox
                </div>
                <Button
                    onClick={
                        (e) => { doNothing(e); setShowInboxList((prev) => !prev); }
                    }
                    text={(
                        <IconWrapper
                            svg={<ChevronDown className="w-3 h-3" />}
                            className={
                                `${showInboxList
                                    ? 'rotate-180 transition ease-in-out duration-300'
                                    : 'rotate-0 transition ease-in-out duration-300'}`
                            }
                        />
                    )}
                    type={ButtonType.plain}
                    externalStyles="flex justify-end pr-0 py-1 pl-3 focus:ring-0"
                />
            </Link>
            <div className={showInboxList ? 'transform opacity-100 scale-100 transition-transform' : 'transform opacity-0 scale-95 h-0 transition-transform'}>
                <Link
                    to={routes.MAIL_RULES}
                    className={`${styles.link} ${styles.subHeading} ${styles.inactive}`}
                    active={effectivePath === routes.MAIL_RULES}
                    activeClassName={`${styles.link} ${styles.subHeading} ${styles.active}`}
                    onClick={onClick}
                >
                    Rules
                </Link>
                <Link
                    to={routes.MONITORED_LABELS}
                    className={`${styles.link} ${styles.subHeading} ${styles.inactive}`}
                    active={effectivePath.startsWith(routes.MONITORED_LABELS)}
                    activeClassName={`${styles.link} ${styles.subHeading} ${styles.active}`}
                    onClick={onClick}
                >
                    Smart Folders
                </Link>
                <Link
                    to={routes.EMAIL_DOMAINS}
                    className={`${styles.link} ${styles.subHeading} ${styles.inactive}`}
                    active={effectivePath.startsWith(routes.EMAIL_DOMAINS)}
                    activeClassName={`${styles.link} ${styles.subHeading} ${styles.active}`}
                    onClick={onClick}
                >
                    Known Domains
                </Link>
            </div>

            <div className="relative">
                <Link
                    to={routes.CALENDAR}
                    className={`flex justify-between ${styles.link} ${styles.heading} ${styles.inactive} ${styles.disabled}`}
                    active={router.asPath.startsWith(routes.CALENDAR)}
                    activeClassName={`${styles.link} ${styles.heading} ${styles.active}`}
                    onClick={onClick}
                >
                    <div className="flex">
                        <AtomOutline className="w-4 h-4 mr-3" />
                        AI Assistant
                    </div>
                    <Button
                        onClick={
                            (e) => { doNothing(e); setShowCalendarList((prev) => !prev); }
                        }
                        text={(
                            <IconWrapper
                                svg={<ChevronDown className="w-3 h-3" />}
                                className={
                                    `${showCalendarList
                                        ? 'rotate-180 transition ease-in-out duration-300'
                                        : 'rotate-0 transition ease-in-out duration-300'}`
                                }
                            />
                        )}
                        type={ButtonType.plain}
                        externalStyles="flex justify-end pr-0 py-1 pl-3 focus:ring-0 w-3"
                    />
                </Link>

                <div className={showCalendarList ? 'transform opacity-100 scale-100 transition-transform' : 'transform opacity-0 scale-95 h-0 transition-transform'}>
                    <Link
                        to={routes.AGENDAS}
                        className={`${styles.link} ${styles.subHeading} ${styles.inactive}`}
                        active={effectivePath.startsWith(routes.AGENDAS)}
                        activeClassName={`${styles.link} ${styles.subHeading} ${styles.active}`}
                        onClick={onClick}
                    >
                        Agendas
                    </Link>

                    <Link
                        to={routes.BIO}
                        className={`${styles.link} ${styles.subHeading} ${styles.inactive}`}
                        active={effectivePath === routes.BIO}
                        activeClassName={`${styles.link} ${styles.subHeading} ${styles.active}`}
                        onClick={onClick}
                    >
                        Brief
                    </Link>

                    <Link
                        to={routes.ALERTS}
                        className={`${styles.link} ${styles.subHeading} ${styles.inactive} ${styles.alpha}`}
                        active={effectivePath.startsWith(routes.ALERTS)}
                        activeClassName={`${styles.link} ${styles.subHeading} ${styles.active}`}
                        onClick={onClick}
                    >
                        Smart Alerts
                    </Link>

                    <Link
                        to={routes.BIRTHDAYS}
                        className={`${styles.link} ${styles.subHeading} ${styles.inactive}`}
                        active={effectivePath === routes.BIRTHDAYS}
                        activeClassName={`${styles.link} ${styles.subHeading} ${styles.active}`}
                        onClick={onClick}
                    >
                        Outreach
                    </Link>

                    <Link
                        to={routes.ROOT}
                        className={`${styles.link} ${styles.subHeading} ${styles.inactive} ${styles.disabled}`}
                        // active={effectivePath === routes.ALERTS}
                        activeClassName={`${styles.link} ${styles.subHeading} ${styles.active}`}
                        onClick={doNothing}
                    >
                        Meeting Score
                    </Link>

                    <Link
                        to={routes.ROOT}
                        className={`${styles.link} ${styles.subHeading} ${styles.inactive} ${styles.disabled}`}
                        // active={effectivePath === routes.ALERTS}
                        activeClassName={`${styles.link} ${styles.subHeading} ${styles.active}`}
                        onClick={doNothing}
                    >
                        Out of Office
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;
